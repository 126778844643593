<template>
    <div class="text-center" style="margin-top:40vh">
      <h2>403</h2>
      <p>{{ $t('forbiddenError.title') }}</p>
      <v-btn color="primary" depressed @click.native="$router.push({ path: '/' })" rounded>{{ $t('forbiddenError.button') }}</v-btn>
  </div>
</template>
<script>
export default {
  name: 'Forbidden'
}
</script>
